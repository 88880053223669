import React from "react";

export const useContactData = () => {
  return {
    metaInfo: {
      title: "Kontakt",
      ogDescription:
        "Dołożymy wszelkich starań, by jak najlepiej odpowiedzieć na potrzeby Twojej firmy. Zaufaj profesjonalistom i zacznij z nami współpracę już dziś.",
    },
    hero: {
      title: (
        <>
          <span style={{ color: "#fbee00" }}>Skontaktuj</span> się z nami
        </>
      ),
      subtitle:
        "Dołożymy wszelkich starań, by jak najlepiej odpowiedzieć na potrzeby Twojej firmy. Zaufaj profesjonalistom i zacznij z nami współpracę już dziś.",
    },
    address: {
      title: "Adres firmy",
      abode: {
        title: "Adres siedziby",
        text: (
          <>
            ul. Wolności 261 <br />
            37-403 Pysznica <br />
            NIP: 865-242-92-88
          </>
        ),
      },
      info: {
        title: "Biuro czynne 7:30-15:30",
        contactInfo: [
          {
            email: "biuro@wumax.pl",
            title: "Nasz email",
          },
          {
            number: "+48 691 967 840",
            title: "Nasz nr. telefonu",
          },

          {
            number: "+48 532 202 777",
            title: "Nasz nr. telefonu",
          },
          {
            number: "+48 787 994 777",
            title: "Nasz nr. telefonu",
          },
        ],
      },
    },
    companyData: {
      title: "Dane firmy",
      abode: {
        title: "Adres FV i rejestracji działalności",
        text: (
          <>
            Wumax Agata Wermińska <br />
            ul. Eugeniusza Kwiatkowskiego 19 <br />
            37-400 Nisko
            <br />
            NIP: 865-242-92-88
          </>
        ),
      },
      info: {
        title: "Pomoc Techniczna",
        name: "Bartłomiej Wermiński",

        contactInfo: [
          {
            email: "b.wermiński@wumax.pl",
            title: "Email pomocy technicznej",
          },
          {
            number: "+48 733 112 202",
            title: "Nr. telefonu pomocy technicznej",
          },
        ],
      },
    },
    form: {
      title: "Napisz do nas",
      text: (
        <>
          Zapraszam serdecznie do kontaktu, <br /> odpowiemy na każde pytanie.
        </>
      ),
      name: "Twoje imię",
      company: "Nazwa firmy",
      email: "Twój email",
      phone: "Telefon komórkowy",
      message: "Twoja wiadomość",
      button: "Wyślij",
      close: "Zamknij",
    },
  };
};
