import React, {useContext, useRef} from "react";
import {CONTACT_IFRAME_NAME, Form} from "components/Form/Form";
import {Paragraph} from "components/Typography/Typography";
import {BoxData, ContactBox, ContactData, Container, Icon, IconWrapper, StyledParagraph, StyledSectionWrapper, SubTitle, TextBox, Title,} from "./styles";
import Pinbar from "assets/svg/contact-black.svg";
import Email from "assets/svg/email-black.svg";
import Phone from "assets/svg/phone-black.svg";
import {ContactLink} from "sections/Contact/styles";
import Hero from "components/Hero/Hero";
import SEO from "components/seo";
import uuid from "react-uuid";
import {useScrollTo} from "shared/Hooks/useScrollTo";
import {StateContext} from "provider/StateProvider";
import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

export const ContactLayout = ({data, heroRef}) => {
  const formRef = useRef(null);
  const {locale} = useContext(StateContext);
  const handleScroll = useScrollTo(formRef);

  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="black">
      </Hero>
      <SectionsWrappper>
        <Container>
          <StyledSectionWrapper>
            <ContactBox>
              <Title>{data.address.title}</Title>
              <SubTitle>{data.address.abode.title}</SubTitle>
              <BoxData>
                <ContactData className="address">
                  <IconWrapper>
                    <Icon src={Pinbar} alt="pinbar"/>
                  </IconWrapper>
                  <StyledParagraph>{data.address.abode.text}</StyledParagraph>
                </ContactData>
              </BoxData>
              <SubTitle>{data.address.info.title}</SubTitle>
              <BoxData>
                {data.address.info.contactInfo.map((contact) => (
                  <ContactData key={uuid()}>
                    <ContactLink
                      href={
                        contact.email
                        ? `mailto:${contact.email}`
                        : `tel:${contact.number}`
                      }
                      title={contact.title}
                    >
                      <IconWrapper>
                        <Icon src={contact.email ? Email : Phone} alt=""/>
                      </IconWrapper>
                      <StyledParagraph className={contact.email ? "bold" : ""}>
                        {contact.email || contact.number}
                      </StyledParagraph>
                    </ContactLink>
                  </ContactData>
                ))}
              </BoxData>
            </ContactBox>
            <ContactBox>
              <Title>{data.companyData.title}</Title>
              <SubTitle>{data.companyData.abode.title}</SubTitle>
              <BoxData>
                <ContactData className="address">
                  <IconWrapper>
                    <Icon src={Pinbar} alt=""/>
                  </IconWrapper>
                  <StyledParagraph>{data.companyData.abode.text}</StyledParagraph>
                </ContactData>
              </BoxData>
              <SubTitle>{data.companyData.info.title}</SubTitle>
              <BoxData className="column">
                <StyledParagraph className="bold name">
                  {data.companyData.info.name}
                </StyledParagraph>
                {data.companyData.info.contactInfo.map((contact) => (
                  <ContactData className="column" key={uuid()}>
                    <ContactLink
                      href={
                        contact.email
                        ? `mailto:${contact.email}`
                        : `tel:${contact.number}`
                      }
                      title={contact.title}
                    >
                      <IconWrapper>
                        <Icon src={contact.email ? Email : Phone} alt=""/>
                      </IconWrapper>
                      <StyledParagraph>
                        {contact.email || contact.number}
                      </StyledParagraph>
                    </ContactLink>
                  </ContactData>
                ))}
              </BoxData>
            </ContactBox>
          </StyledSectionWrapper>
        </Container>
        <Container color="gray" ref={formRef}>
          <StyledSectionWrapper>
            <TextBox>
              <Title>{data.form.title}</Title>
              <Paragraph>{data.form.text}</Paragraph>
            </TextBox>
            <Form data={data.form} handleScroll={handleScroll} name={CONTACT_IFRAME_NAME} src={locale === "en" ? process.env.GATSBY_CONTACT_FORM_URL_EN : process.env.GATSBY_CONTACT_FORM_URL_PL}/>
          </StyledSectionWrapper>
        </Container>
      </SectionsWrappper>

    </>
  );
};
