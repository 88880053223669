import React, {useEffect, useRef} from "react";
import {FormBody, FormTitle, Iframe, IframeWrapper} from "./styles";

export const CONTACT_IFRAME_NAME = "WUMAX_CONTACT_FORM";

export const Form = ({data, modal, handleScroll, name, src}) => {
  /** @type {MutableRefObject<HTMLIFrameElement>} */
  const iframe = useRef(null);
  useEffect(() => {
    const handler = (event) => {
      if (!iframe.current || !event.origin.startsWith(process.env.GATSBY_EXTERNAL_FORMS_ORIGIN) || event.data.name !== name) {
        return;
      }

      if (event.data.height) {
        iframe.current.style.height = event.data.height + "px";
      }

      if (event.data.offset) {
        if(handleScroll) {
          handleScroll(event);
        }
      }

      // if (event.data.type === "form-success") {
      //   window.dataLayer.push({'event': event.data.name})
      // }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [name, src, handleScroll]);

  return (
    <FormBody className={modal ? 'modal' : ''}>
      {modal && <FormTitle>{data.title}</FormTitle>}
      <IframeWrapper>
        <Iframe ref={iframe} src={src} name={name} title="Formularz kontaktowy"/>
      </IframeWrapper>
    </FormBody>
  );
};

