import styled from "styled-components";
import {Paragraph, TitleL, TitleXL} from "components/Typography/Typography";
import {SectionWrapper} from "shared/Wrappers/wrappers";

export const Container = styled.section`
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-color: ${({color, theme}) =>
            color === "gray" ? theme.colors.gray_light_bg : theme.colors.white};
    z-index: 3;
    position: relative;
    padding: 48px 0;
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const ContactBox = styled.div`
    display: flex;
    flex-direction: column;

    &:first-child {
        width: 33%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        &:first-child {
            width: 40%;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        &:first-child {
            width: 50%;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100%;
        align-items: center;
        text-align: center;
        &:first-child {
            width: 100%;
            margin-bottom: 12px;
        }
    }
`;

export const Title = styled(TitleXL)`
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.l.fhd};
    }
`;

export const SubTitle = styled(TitleL)`
    font-weight: ${({theme}) => theme.weights.normal};
    margin-top: 36px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.m.tablet};
    }
`;

export const BoxData = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;

    &.column {
        flex-direction: column;
    }

    &:last-child {
        justify-content: flex-end;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-top: 36px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        margin-top: 32px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        margin-top: 28px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        align-items: center;

        &.column {
            margin-top: 20px;
        }
    }
`;

export const ContactData = styled.div`
    width: 50%;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.address {
        width: 100%;
        margin-bottom: 0;
    }

    &.column {
        width: 100%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: column;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_small}) {
        width: 100%;
    }
`;

export const ContactLink = styled.a`
    display: flex;
    align-items: center;

    &.address {
        width: 100%;
        margin-bottom: 0;
    }

    &.column {
        width: 100%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: column;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    font-size: 16px;

    &.name {
        margin-bottom: 24px;
    }

    &.bold {
        font-weight: ${({theme}) => theme.weights.bold};
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.s.tablet};
    }
`;

export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 24px;
    flex-shrink: 0;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 25px;
        height: 25px;
        margin-right: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-right: 0;
        margin-bottom: 12px;
    }
`;

export const Icon = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
`;

export const TextBox = styled.div`
    p {
        margin-top: 36px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 36px;
        p {
            text-align: center;

            br {
                display: none;
            }
        }
    }
`;
