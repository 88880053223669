export const useScrollTo = (ref, isModal) => (event) => {
  if(isModal) {
    ref.current.scroll({
      top: 0 + event.data.offset,
      left: 0,
      behavior: "smooth",
    });
  } else {

    let windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if(windowWidth < 1101) {
      windowHeight = 0;
    }

    window.scroll({
      top: ref.current.offsetTop + event.data.offset + windowHeight,
      left: 0,
      behavior: "smooth",
    });
  }
}
