import styled from "styled-components";
import {TitleM} from "components/Typography/Typography";

export const FormBody = styled.div`
    width: 50%;
    height: 100%;

    &.modal {
        width: 100%;
    }

    @media screen and (max-width: ${({theme}) =>
  theme.breakpoints.tablet_small}) {
        width: 100%;
    }
`;

export const FormTitle = styled(TitleM)`
    margin-bottom: 24px;
`;

export const IframeWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;

`;

export const Iframe = styled.iframe`
    width: 100%;
    display: block;
    border: none;
    
`;
